<template>
  <div class="pr">
   <div class="header pf flex-jb-al">
      <div class="header-left flex-al">
        <img src="../assets/images/logo.png">
        <!-- <span class="np">智易宝信息管理系统 </span> -->
      </div>
      <div class="header-right flex-al">
        <!-- <div class="header-right-l flex">
          <img class="w-32"  src="../assets/images/code.png" alt="">
          <img class="mr-30 ml-30 w-32" src="../assets/images/tips.png" alt="">
          <div class>
          <img class="w-32" src="../assets/images/remind.png" alt="">
            <div class="is-fixed">12</div>
          </div>
        </div> -->
        <div class="header-right-r flex-al" @click="$router.push('/account')">
          <img v-if='$store.state.infor.avatar'  class="own" :src="$store.state.infor.avatar" alt>
          <img v-else  class="own" src="../assets/images/me.png" alt>
          <!-- <i v-else class="f24 el-icon-s-custom"></i> -->
          <!-- <i style="font-size:16px" class="el-icon-arrow-down"></i>  -->
          <!-- <div>个人中心</div> -->
        </div>
        <div @click="tc" class="tc">退出</div>
      </div>
   </div>
   <div class="aside pf">
      <el-menu
          router
          :default-active="$store.state.active"
          class="el-menu-vertical-demo"
        >
          <el-menu-item index="/index" @click="indexActive('/index')">
            <i class="el-icon-house"></i>
            <span slot="title">首页</span>
          </el-menu-item>
          <el-menu-item index="/monitoring" @click="indexActive('/monitoring')">
            <i class="el-icon-view"></i>
            <span slot="title">年费监控</span>
          </el-menu-item>
          <el-menu-item index="/contract" @click="indexActive('/contract')">
            <i class="el-icon-document"></i>
            <span slot="title">我的合同</span>
          </el-menu-item>
          <el-menu-item index="/case" @click="indexActive('/case')">
            <i class="el-icon-notebook-1"></i>
            <span slot="title">我的案件</span>
          </el-menu-item>
          <el-menu-item index="/trade" @click="indexActive('/trade')">
            <i class="el-icon-s-operation"></i>
            <span slot="title">我的交易</span>
          </el-menu-item>
          <el-menu-item index="/order" @click="indexActive('/order')">
            <i class="el-icon-copy-document"></i>
            <span slot="title">我的订单</span>
          </el-menu-item>
           <el-menu-item index="/appointment" @click="indexActive('/appointment')">
            <i class="el-icon-edit-outline"></i>
            <span slot="title">免费预约</span>
          </el-menu-item>
        </el-menu>
        
   </div>
   <!-- <div class="footer pf"></div> -->
   <div class="main">
   <router-view  v-loading="$store.state.loading"
    element-loading-background="rgba(255, 255, 255, 0.1)"></router-view>
   </div> 
   
  </div>
</template>

<script>
export default {
  created(){
    this.getMember()
    this.$store.commit('increment', window.sessionStorage.active?window.sessionStorage.active:'/index')
  },
  data(){
    return {
     
    }
  },
    methods:{
     indexActive(index){
         this.$store.commit('increment', index)
      },
    getMember() {
      this.$request.get("/client/member/info").then(res => {
        if (res.data.code == 200) {
          let infor = res.data.data||{}; 
          this.$store.commit('getinfor', infor)         
        }
      });
    },
    tc(){
       this.$confirm('是否退出?', '退出后，再次进入需重新登陆', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          window.localStorage.clear()
          this.$router.push('/login')
        }).catch(() => {          
        });
       
     
    }
    }
}
</script>

<style>
.w-32{
  width: 32px;
  height: 32px;
}
.header{
  height: 80px;
  padding: 0 90px;
  width: 100%;
  top:0;
  z-index: 100;
  background-color: #0b1e52;
  color: #fff;
  box-sizing: border-box;
}
.header-left img {
  height: 60px;
  margin-right: 30px;
}
.header-left span {
  font-size: 24px;
}
.header-right .own {
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  border-radius: 50%;
  margin-left: 130px;
  margin-right: 10px;
}
.header-right-l i {
  margin-left: 60px;
  font-size: 30px;
}
.header-right-l > div {
  position: relative;
}
.header-right-r{
  cursor: pointer;
}
.is-fixed {
  background-color: #F13D3D;
  border-radius: 10px;
  color: #fff;
  font-size: 12px;
  height: 18px;
  line-height: 18px;
  padding: 0 6px;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #fff;
  display: inline-block;
  position: absolute;
  right: -10px;
}
.aside{
  top:80px;
  bottom: 0;
  left: 0;
  width: 180px;
  background-color: #fff;
  z-index: 1
}
.el-menu-item.is-active{
  color: #2962FF;
  border-left: 4px solid #2962FF;
  background-color: rgba(234, 246, 255)
}
.el-menu-item{
  font-size: 14px;
  border-left: 4px solid #fff;
  color: #000000,
}
.el-menu-item.is-active i{
  color: transparent;
}
.footer{
  bottom: 0;
  left: 180px;
  right: 0;
  height: 80px;
  background-color:#fff;
  z-index: 10;
}
.main{
  padding: 100px 20px 30px 200px;
}
.el-loading-spinner .path{
      stroke: #2962FF;
    }
.tc{
  height: 44px;
  width: 86px;
  color: #fff;
  /* background-color: #ccc; */
  text-align: center;
  line-height: 44px;
  border-radius: 6px;
  margin-left:20px;
  cursor: pointer;
}
.el-button--primary {
    background-color: #2962FF !important;
    border-color: #2962FF !important;
}
.el-message-box{
  padding: 20px !important;
} 
</style>